import { type ReactNode } from 'react';
import { SupplierSubscriptionsTable } from './table';
import { SupplierSubscriptionsSidebar } from './sidebar/sidebar';

export function SupplierSubscriptions(): ReactNode {
  return (
    <div>
      <SupplierSubscriptionsTable />
      <SupplierSubscriptionsSidebar />
    </div>
  );
}
